const slides = [
  {
    image: "./assets/bmt/medical_professionals.png",
  },
  {
    image: "./assets/bmt/medical_facility.png",
  },
  {
    image: "./assets/bmt/doctor.png",
  },
  {
    image: "./assets/bmt/medical_professionals.png",
  },
  {
    image: "./assets/bmt/operating_room.png",
  },
  {
    image: "./assets/bmt/happy_users.png",
  },
];

export default slides;
