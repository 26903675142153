const slidesSm = [
  {
    image: "./assets/bmt/low5.png",
  },
  {
    image: "./assets/bmt/low4.png",
  },
  {
    image: "./assets/bmt/low3.png",
  },
  {
    image: "./assets/bmt/low2.png",
  },
  {
    image: "./assets/bmt/low1.png",
  },
];

export default slidesSm;
